.main {
    display: flex;
    margin: 200px auto;
    justify-content: center;
    align-items: center;
}

.formik {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}


.formik input {
    padding: 10px;
    border: none;
    margin: 5px;
    text-align: center;
    border-radius: 50px;
}

.msg {
    width: 30vw;
    height: 30vh;
}

button {
    padding: 8px;
    background-color: blanchedalmond;
    width: 120px;
    text-align: center;
    border-radius: 25px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

button:hover {
    background: rgb(201, 154, 85);
}